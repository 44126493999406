import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useA1BatchesProvider} from '../../../../../../gori/providers'
import {Button, UploadMultipleFile, ValidationErrorModal} from '../../../../../../gori/widgets'
import {A1BatchService, A1_CONSTANTS, T86DocumentTable} from '../../../index'
import {useCancelToken, UsePermission} from '../../../../../../gori/hooks'
import {useParams} from 'react-router-dom'

type Props = {
  show: boolean
  handleClose: () => void
}

const T86DocumentModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {id} = useParams()
  const {a1Batch} = useA1BatchesProvider()
  const {isCancel, newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [reloadForm, setReloadForm] = useState<boolean>(false)
  const {isOrigin} = UsePermission()

  const [loading, setLoading] = useState<{
    list: boolean
    update: boolean
    download: boolean
  }>({
    list: false,
    update: false,
    download: false,
  })

  const {PICK_UP_DROP_OFF} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  const canEdit = useMemo(() => {
    return isOrigin && PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status
  }, [isOrigin, PICK_UP_DROP_OFF, a1Batch?.journey_status])

  const handleCloseModal = () => {
    handleClose()
  }

  const initValidateSchema = useMemo(() => {
    return {
      files: Yup.array().min(
        1,
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: intl.formatMessage({id: 'T86_DOCUMENTATIONS'})}
        )
      ),
    }
  }, [intl])

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      try {
        setReloadForm(false)
        setLoading((prev) => ({...prev, update: true}))
        const config = {cancelToken: newCancelToken()}
        const payload = new FormData()
        // @ts-ignore
        payload.append('batch_id', id)
        const files = values?.files
        files.map((file) => {
          payload.append('files[]', file)
          return file
        })
        const res: any = await A1BatchService.uploadT86Documents(a1Batch.id, payload, config)
        if (res) {
          toast.success(res.message)
          formik.resetForm()
          setReloadForm(true)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_upload_t86_documents_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'T86_DOCUMENTATIONS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              {canEdit && (
                <div className='mt-10 upload-card'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h3 className='required'>
                      {intl.formatMessage({id: 'T86_CLEARANCE_DOCUMENTATIONS'})}
                    </h3>
                  </div>
                  <UploadMultipleFile
                    className='bg-light-primary border-primary border-2 mt-5'
                    onSubmit={(e) => formik.setFieldValue('files', e.files)}
                    supportedFormat={A1_CONSTANTS.UPLOAD_CONFIG.PDF_FORMAT}
                    size={{label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE}}
                    reloadForm={reloadForm}
                  />
                  {formik.errors.files && formik.touched.files && (
                    <div className='text-danger mt-2'>{formik.errors.files}</div>
                  )}
                </div>
              )}
              <T86DocumentTable reloadTable={reloadForm} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              {canEdit && (
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'UPDATE'})}
                  disabled={loading.update}
                  event={formik.handleSubmit}
                  loading={loading.update}
                />
              )}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {T86DocumentModal}
